<div class="root">
    <div class="first_div_div">
      <div class="w-1280 first_div row mt-4">
        <div
          class="d-flex gap-4 flex-column justify-content-around px-0 col-md-12 col-lg-6"
        >
          <div class="transform">Make Your CSR Initiatives Extraordinary with Shohay.</div>
          <div class="streamline">
            Automate giving, engage your teams, and see real results fast.
          </div>
          <div class="d-flex gap-4 mb-4">
            <button class="blue-button-lg">
                Watch Demo
            </button>
            <button class="yellow-button-lg">Get Started</button>
          </div>
        </div>
    
        <div class="px-0 col-md-12 col-lg-6">
          <img width="100%" src="/assets/images/Group_39578.jpg" />
        </div>
      </div>
    </div>
</div>
