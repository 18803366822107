import { Component } from '@angular/core';

@Component({
  selector: 'app-chp3-key-features',
  templateUrl: './chp3-key-features.component.html',
  styleUrl: './chp3-key-features.component.scss'
})
export class Chp3KeyFeaturesComponent {

}
