import { Component } from '@angular/core';

@Component({
  selector: 'app-chp7-watch-shohay',
  templateUrl: './chp7-watch-shohay.component.html',
  styleUrl: './chp7-watch-shohay.component.scss'
})
export class Chp7WatchShohayComponent {

}
