<div class="window">
  <div class="home_page">
    <app-donation-buttons
      style="background-color: antiquewhite"
    ></app-donation-buttons>
    <app-hp1-hero></app-hp1-hero>
    <app-hp-new-camping-wall></app-hp-new-camping-wall>
    <app-hp-new-hall-of-fame></app-hp-new-hall-of-fame>
    <app-hp2-article-news></app-hp2-article-news>
    <app-hp4-sign-up></app-hp4-sign-up>
    <app-hp2-new-discover-shohay></app-hp2-new-discover-shohay>
    <app-hp9-contact-form></app-hp9-contact-form>
  </div>
</div>
