import { Component } from '@angular/core';

@Component({
  selector: 'app-chp6-visual-donations',
  templateUrl: './chp6-visual-donations.component.html',
  styleUrl: './chp6-visual-donations.component.scss'
})
export class Chp6VisualDonationsComponent {

}
