<div class="root">
    <img src="/assets/images/watch-shohay.png" alt="">

    <div [routerLink]="'/auth/corporate/signup'" class="sign_up_link cursor">
        Sign Up for Free
        <img
          class="arrow-right-alt-icon"
          loading="lazy"
          alt=""
          src="../../../../../assets/images/icon/arrow-right-alt.svg"
        />
    </div>
</div>