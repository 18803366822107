<div class="root">
  <div class="last_div">
    <div class="our_product">
      See the ROI of Corporate Giving with Shohay.
    </div>
    <!-- <div class="streamline text-light mb-5">
          Have an NGO? Simplify management with our advanced platform, or
          Starting a New NGO? Register today and kickstart your initiatives with
          our comprehensive support
        </div> -->

    <button
      class="yellow-button-lg"
      style="border: 2px solid #ffd602;"
    >
      Calculate your ROI
    </button>
  </div>
</div>
