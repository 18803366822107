<div class="root">
  <!-- <div class="our_product mb-2 mt-3">Key Features at a Glance</div> -->
  <div class="w-1280">
    <div class="row mt-5">
      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Employee Engagement</div>
        <div class="streamline">
          Boost team participation and foster collaboration. Empower employees
          through meaningful engagement initiatives.
        </div>
        <div class="whatever_your fw-bolder">How It Works:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Create campaigns that align with corporate and employee interests.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Employees participate by contributing or volunteering through
            Shohay.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Track engagement metrics, recognize top contributors, and share
            updates. .</span
          >
        </div>
      </div>

      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39555.jpg" />
      </div>
    </div>
  </div>
</div>
