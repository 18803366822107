<div class="root">
  <div class="our_product mb-2 mt-3">
    Key Features at a Glance
  </div>
  <div class="w-1280">
    <div class="row mt-5">
      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Corporate Matching Management</div>
        <div class="streamline">
          Quickly set up and manage matching gifts. Simplify employee giving by
          automating corporate matches.
        </div>
        <div class="whatever_your fw-bolder">How It Works:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Set matching policies (e.g., percentage, employee limits).</span
          >
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Employees donate via Shohay s portal.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Corporate contributions are automatically tracked and matched.
            .</span
          >
        </div>
      </div>

      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/key-features.png" />
      </div>
    </div>
  </div>
</div>
