import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ICampaign } from 'src/app/shared/interface/campaign/campaign.interface';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-buttons',
  templateUrl: './donation-buttons.component.html',
  styleUrl: './donation-buttons.component.scss',
})
export class DonationButtonsComponent {
  public featuredCampaigns: any[] = [];
  public groupedCampaigns: any[][] = [];

  public handlingLessThanThreeCampaigns: boolean = false;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly campaignService: NgoCampaignService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.campaignService.getFeaturedNgoDonationCampaign().subscribe({
      next: (result) => {
        this.featuredCampaigns = result;
        this.groupedCampaigns = this.chunkArray(this.featuredCampaigns, 3);
      }
    });
  }

  public chunkArray(array: any[], size: number): any[][] {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }

  public navigateToCampaign(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
      id: campaign?.id,
    });
    this.router.navigate(['/campaign'], {
      queryParams: { data: encryptedParams },
    });
  }
}
