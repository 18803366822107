<div class="root" *ngIf="featuredCampaigns.length">
  <ngb-carousel style="width: 100%" [interval]="8000">
    <ng-template ngbSlide *ngFor="let campaignGroup of groupedCampaigns">
      <div class="slide_div">
        <div class="inner_half_slide" *ngFor="let campaign of campaignGroup">
          <div class="info_div cursor" (click)="navigateToCampaign(campaign)">
            <div
              class="left_side"
              [ngStyle]="{
                'background-image': 'url(' + campaign?.cover_img_vdo?.url + ')'
              }"
            >
              <div class="yellow_title_div">
                <div class="yellow_title">Donate</div>
              </div>
            </div>
            <div class="right_side">
              <div class="ngo_name">{{ campaign?.ngo_name }}</div>
              <div class="campaign_title">{{ campaign?.title }}</div>
              <div>
                <dx-progress-bar
                  class="progress_bar"
                  [min]="0"
                  [max]="campaign?.fundraising_target"
                  [value]="campaign?.total_fund_raised"
                  [showStatus]="false"
                  [hint]="
                    campaign?.total_fund_raised / campaign?.fundraising_target
                      | percent
                  "
                ></dx-progress-bar>
              </div>
              <div class="campaign_target">
                Target - ৳ {{ campaign?.fundraising_target | number }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>

  <div class="indicator_div" *ngIf="featuredCampaigns.length">
    <div class="see_all" routerLink="/all-donation-campaigns">
      {{ "slider.see-more" | translate }}
    </div>
  </div>
</div>
