import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { CampaignDonationTransactionModel } from 'src/app/shared/model/campaign-donation.model';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import jsPDF from 'jspdf';
import { pledgeDonationModel } from 'src/app/shared/model/pledge-donation.model';

@Component({
  selector: 'app-campaign-donate',
  templateUrl: './campaign-donate.component.html',
  styleUrl: './campaign-donate.component.scss',
})
export class CampaignDonateComponent {
  public suggestedFund: any = [
    { amount: 500, isSuggested: false },
    { amount: 1000, isSuggested: false },
    { amount: 10000, isSuggested: true },
    { amount: 20000, isSuggested: false },
    { amount: 50000, isSuggested: false },
  ];
  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/search_icon.svg',
    stylingMode: 'text',
  };

  public sessionUser: any = null;
  private ngo_id: string = null;
  private project_id: string = null;
  private campaign_id: string = null;

  public transactionFeeStatus: boolean = true;
  public donationInput: number = null;

  public donationSummery: any;

  public fundRaiserTeams: any[] = [];
  public teamMembers: any[] = [];
  public allMembers: any[] = [];
  public receiptNumber: number = 0;

  public totalDonationModel: CampaignDonationTransactionModel =
    new CampaignDonationTransactionModel();

  public pledgeDonationModel: pledgeDonationModel = new pledgeDonationModel();
  public pledgeStatus: boolean = false;

  public campaigns: any = [
    {
      organization: '',
      campaign: '',
      amount: 0,
    },
  ];
  public downloadPaymentReceipt: boolean = false;
  public isSuccessful: boolean = false;
  public currentDate: Date = new Date();
  public checkCommentStatus: boolean = false;

  public termsPopup: boolean = false;
  public privacyPopup: boolean = false;
  public pricingPopup: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    public readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService,
    public readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaign_id = decryptedParams.id;
        this.ngo_id = decryptedParams.ngo_id;
        this.project_id = decryptedParams.project_id;
        if (this.campaign_id && this.ngo_id && this.project_id) {
          this.getAllFundraisers();
          this.getDonationSummery();
        }
      } else {
        this.router.navigateByUrl('/');
        console.error('Decryption failed');
      }
    });
  }

  private getAllFundraisers(): void {
    this.fundraiserService
      .getAllTeamsWithFundraisers(this.campaign_id)
      .subscribe((result: any) => {
        if (result?.length > 0) {
          this.fundRaiserTeams = this.modifyTeamsList(result).sort(
            (a, b) => a.fund_raised - b.fund_raised
          );
          this.teamMembers = JSON.parse(JSON.stringify(this.allMembers));
        }
      });
  }

  private modifyTeamsList(result: any[]): any[] {
    result.forEach((item: any) => {
      item.team_logo = item.team_logo ? JSON.parse(item.team_logo) : null;

      if (item.members) {
        item.id = item.members[0].team_id;
        item.members.forEach((member: any) => {
          member.profile_img = member.profile_img
            ? JSON.parse(member.profile_img)
            : null;

          this.allMembers.push(member);
        });
      }
    });
    return result;
  }

  private getDonationSummery(): void {
    this.donationService
      .getDonationCampaignById(this.ngo_id, this.project_id, this.campaign_id)
      .subscribe({
        next: (result: any) => {
          this.donationSummery = result;
          this.donationSummery.cover_img_vdo = JSON.parse(
            this.donationSummery.cover_img_vdo
          );
          this.fillNecessaryProperties();
        },
      });
  }

  private fillNecessaryProperties(): void {
    this.totalDonationModel.ngo_name = this.donationSummery.ngo_name;
    this.totalDonationModel.campaign_title = this.donationSummery.title;

    this.totalDonationModel.ngo_id = this.ngo_id;
    this.totalDonationModel.project_id = this.project_id;
    this.totalDonationModel.campaign_id = this.campaign_id;

    this.sessionUser = this.localStorageService.getSessionUser();
    if (this.sessionUser) {
      this.totalDonationModel.email = this.sessionUser?.email;
      this.totalDonationModel.name = this.sessionUser?.name;
    }
  }

  public selectedAmountFunc(amount: number) {
    this.donationInput = amount;
  }

  public onTeamSelected(e: any): void {
    if (e.value) {
      const team = this.fundRaiserTeams.find((item) => item.id === e.value);
      this.teamMembers = team.members ? team.members : [];
    } else if (e.value === null) {
      this.totalDonationModel.fundraiser_id = null;
      this.totalDonationModel.team_id = null;
      this.teamMembers = JSON.parse(JSON.stringify(this.allMembers));
    }
  }

  public selectFundraisingMember(member: any): void {
    this.totalDonationModel.team_id = member.team_id;
    this.totalDonationModel.fundraiser_id = member.id;
  }

  public searchFundraisingMember(e: any): void {
    if (e.value.length > 0) {
      this.totalDonationModel.team_id = '';
      this.totalDonationModel.fundraiser_id = null;

      this.teamMembers = this.allMembers.filter((item) =>
        item?.fundraiser_name?.toLowerCase()?.includes(e.value.toLowerCase())
      );
    } else if (e.value.length === 0) {
      this.totalDonationModel.team_id = '';
      this.totalDonationModel.fundraiser_id = null;
      this.onTeamSelected({ value: null });
    }
  }

  public onSubmit(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.donationService
        .makeCampaignDonation(this.totalDonationModel)
        .subscribe({
          next: (res) => {
            this.successDonation(res);
          },
        });
    } else {
      this.utilitiesService.showSwalWithToast(
        'Warning',
        'Fill all the necessary field',
        'warning'
      );
    }
  }

  private successDonation(res: any): void {
    this.isSuccessful = true;
    this.generateRandomSixDigitNumber();
    this.ngOnInit();
  }

  private successPledge(res: any): void {
    this.isSuccessful = true;
    this.pledgeStatus = true;
    this.ngOnInit();
  }

  public getDonationAmount(): number {
    this.totalDonationModel.transaction_fees =
      this.donationInput * this.donationService.DonationFeeRate;
    this.totalDonationModel.amount = this.transactionFeeStatus
      ? this.donationInput || 0
      : this.donationInput - this.totalDonationModel.transaction_fees;

    return this.totalDonationModel.amount;
  }

  public downloadPDF() {
    const pdf = new jsPDF();
    const content = document.getElementById('contentToConvert');
    if (content) {
      pdf.html(content, {
        callback: (pdfInstance) => {
          pdfInstance.save('money-receipt.pdf');
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: 1100,
      });
    }
  }

  public downloadPopup() {
    this.downloadPaymentReceipt = true;
  }

  public check(): void {
    this.checkCommentStatus =
      !this.totalDonationModel.comment ||
      this.totalDonationModel.comment.trim() === '';
  }

  public termsPopUp() {
    this.termsPopup = true;
  }

  public privacyPopUp() {
    this.privacyPopup = true;
  }

  public pricingPopUp() {
    this.pricingPopup = true;
  }

  public generateRandomSixDigitNumber() {
    this.receiptNumber = Math.floor(100000 + Math.random() * 900000);
  }

  public getPledgeBody() {
    return {
      ...this.totalDonationModel,
      pledged_amount: this.totalDonationModel.amount,
      ngo_id: this.ngo_id,
      project_id: this.project_id,
      campaign_id: this.campaign_id,
      admin_id: null,
      pledge_day: new Intl.DateTimeFormat('en-US', { weekday: 'short' })
        .format(new Date())
        .toUpperCase(),
      pledge_month: new Intl.DateTimeFormat('en-US', { month: 'short' })
        .format(new Date())
        .toUpperCase(),
      donor_id: this.sessionUser?.id,
    };
  }

  public submitPledge(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      // const pledgeBody: pledgeDonationModel = {
      //   ngo_id: this.ngo_id,
      //   project_id: this.project_id,
      //   campaign_id: this.campaign_id,
      //   amount: this.totalDonationModel.amount,
      //   email: this.totalDonationModel.email,
      //   name: this.totalDonationModel.name,
      //   transaction_fees: this.totalDonationModel.transaction_fees,
      //   fundraiser_id: this.totalDonationModel.fundraiser_id,
      //   team_id: this.totalDonationModel.team_id,
      //   comment: this.totalDonationModel.comment,
      //   is_video_call_active: this.totalDonationModel.is_video_call_active,
      //   whatsapp_no: this.totalDonationModel.whatsapp_no,
      //   is_hidden_name: this.totalDonationModel.is_hidden_name,
      //   is_hidden_comment: this.totalDonationModel.is_hidden_comment,
      //   can_contact: this.totalDonationModel.can_contact,
      //   is_hidden_name_and_amount:
      //     this.totalDonationModel.is_hidden_name_and_amount,
      //   is_shohay_user: this.totalDonationModel.is_shohay_user,
      //   user_id: this.totalDonationModel.user_id,
      //   admin_id: null,
      //   pledge_day: new Intl.DateTimeFormat('en-US', { weekday: 'short' })
      //     .format(new Date())
      //     .toUpperCase(),
      //   pledge_month: new Intl.DateTimeFormat('en-US', { month: 'short' })
      //     .format(new Date())
      //     .toUpperCase(),
      //   donor_id: this.sessionUser?.id,
      //   ngo_name: this.totalDonationModel.ngo_name,
      //   campaign_title: this.totalDonationModel.campaign_title,
      //   pledged_amount: this.totalDonationModel.amount,
      // };
      const pledgeBody = this.getPledgeBody();
      this.donationService.pledgeDonation(pledgeBody).subscribe({
        next: (res) => {
          this.successPledge(res);
        },
      });
    } else {
      this.utilitiesService.showSwalWithToast(
        'Warning',
        'Fill all the necessary field',
        'warning'
      );
    }
  }
}
