import { Component } from '@angular/core';

@Component({
  selector: 'app-chp4-live-roi',
  templateUrl: './chp4-live-roi.component.html',
  styleUrl: './chp4-live-roi.component.scss'
})
export class Chp4LiveRoiComponent {

}
