<div class="root">
  <div class="second_div py-3 mt-5">
    <div class="our_product mb-3">
      Why Choose Shohay for Your CSR Initiatives?
    </div>
    <!-- <div class="streamline text-center w-80 mb-5">
      At Shohay, we know the challenges and opportunities that NGOs face in
      Bangladesh. Our integrated suite offers the resources and backing
      necessary to gather knowledge, consolidate processes, enhance efficacy,
      and drive societal progress.
    </div> -->

    <div class="row justify-content-between w-100 t-5 for_mobile">
      <div class="py-3 px-0 col-6 form_mobile_cart">
        <div class="why_choose_shohay_card" style="margin-right: 16px">
          <img src="/assets/images/icon/infinity_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">
              Corporate Matching Made Easy
            </div>
            <div class="engage_retain">
              Automate your employee giving matches.
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6 form_mobile_cart">
        <div class="why_choose_shohay_card" style="margin-left: 16px">
          <img src="/assets/images/icon/diversion_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Engaged Employees</div>
            <div class="engage_retain">Empower employees to give back.</div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6 form_mobile_cart">
        <div class="why_choose_shohay_card" style="margin-right: 16px">
          <img src="/assets/images/icon/location_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Real-Time Impact Tracking</div>
            <div class="engage_retain">
              See the difference you’re making as it happens.
            </div>
          </div>
        </div>
      </div>
      <div class="py-3 px-0 col-6 form_mobile_cart">
        <div class="why_choose_shohay_card" style="margin-left: 16px">
          <img src="/assets/images/icon/click_blue.svg" />
          <div class="d-flex gap-3 flex-column">
            <div class="whatever_your fw-bolder">Ready-to-Use Reports</div>
            <div class="engage_retain">Instantly access the data you need.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dx-button [routerLink]="'/auth/corporate/signup'" class="white-button-lg">Register Now</dx-button>
</div>
