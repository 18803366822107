import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent } from 'src/app/shared/components/footer-components/pricing/pricing.component';
import { PrivacyPolicyComponent } from 'src/app/shared/components/footer-components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from 'src/app/shared/components/footer-components/terms-and-conditions/terms-and-conditions.component';
import { CampaignDetailsComponent } from 'src/app/shared/components/ngo-campaign/campaign-details/campaign-details.component';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { AllCampaignListComponent } from './all-campaign-list/all-campaign-list.component';
import { AllDonationCampaignComponent } from './all-donation-campaign/all-donation-campaign.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { ArticleNewsComponent } from './article-news/article-news.component';
import { CampaignDonateComponent } from './campaign-donate/campaign-donate.component';
import { DonationComponent } from './donation/donation.component';
import { AboutComponent } from './footer-components/about/about.component';
import { ContactUsComponent } from './footer-components/contact-us/contact-us.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { HomePageComponent } from './home-page-components/home-page/home-page.component';
import { NgoListComponent } from './ngo/ngo-list/ngo-list.component';
import { NgoPageComponent } from './ngo/ngo-page/ngo-page.component';
import { NgoSearchComponent } from './ngo/ngo-search/ngo-search.component';
import { CorporateHomePageComponent } from './corporate-home/corporate-home-page/corporate-home-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'all-news-articles',
    component: GlobalSearchComponent,
    pathMatch: 'full',
  },
  {
    path: 'search-ngo',
    component: NgoSearchComponent,
    pathMatch: 'full',
  },
  {
    path: 'find-ngo',
    component: NgoListComponent,
    pathMatch: 'full',
  },
  {
    path: 'ngo',
    component: NgoPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'news-article',
    component: ArticleNewsComponent,
    pathMatch: 'full',
  },
  {
    path: 'donation',
    component: DonationComponent,
    pathMatch: 'full',
  },
  { path: 'campaign', component: CampaignDetailsComponent, pathMatch: 'full' },
  {
    path: 'all-relief-campaigns',
    component: AllCampaignListComponent,
    pathMatch: 'full',
  },
  {
    path: 'all-donation-campaigns',
    component: AllDonationCampaignComponent,
    pathMatch: 'full',
  },
  {
    path: 'donate-to-campaign',
    component: CampaignDonateComponent,
    pathMatch: 'full',
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full',
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'about-us',
    component: AboutComponent,
    pathMatch: 'full',
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    pathMatch: 'full',
  },
  {
    path: 'pricing',
    component: PricingComponent,
    pathMatch: 'full',
  },
  {
    path: 'all-products',
    component: AllProductsComponent,
    pathMatch: 'full',
  },
  {
    path: 'corporate-home',
    component: CorporateHomePageComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingRoutingModule {}
