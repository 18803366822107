import { Component } from '@angular/core';

@Component({
  selector: 'app-chp2-choose-shohay',
  templateUrl: './chp2-choose-shohay.component.html',
  styleUrl: './chp2-choose-shohay.component.scss'
})
export class Chp2ChooseShohayComponent {

}
