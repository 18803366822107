import { Component } from '@angular/core';

@Component({
  selector: 'app-chp9-calculate-roi',
  templateUrl: './chp9-calculate-roi.component.html',
  styleUrl: './chp9-calculate-roi.component.scss'
})
export class Chp9CalculateRoiComponent {

}
