import { Component } from '@angular/core';

@Component({
  selector: 'app-corporate-home-page',
  templateUrl: './corporate-home-page.component.html',
  styleUrl: './corporate-home-page.component.scss'
})
export class CorporateHomePageComponent {

}
