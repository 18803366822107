<div class="root">
  <div class="our_product mb-2 mt-3">
    Our Successful Corporate CSR Campaigns
  </div>
  <div class="streamline">
    Discover how Shohay has helped companies like yours make a real impact.
  </div>
  <div class="campaigns_container">
    <div *ngFor="let campaigns of campaignsList" class="campaigns">
      <div
        class="rectangle-parent"
        [style]="'background-image: url(' + campaigns?.cover_photo + ');'"
      ></div>
      <div class="title_campaigns">
        {{ campaigns.campaign_title }}
      </div>
      <div class="second_part">
          <div class="collected"> <span [style]="'font-wight:700'">Collected_Amount : </span>{{ campaigns.Collected_Amount }}</div>
          <div class="collected"> <span [style]="'font-wight:700'">Matched_Amount : </span>{{ campaigns.Matched_Amount }}</div>
          <div class="collected"> <span [style]="'font-wight:700'">total_amount : </span>{{ campaigns.total_amount }}</div>
      </div>
      <div class="view_details">
        View Details
        <img
          class="arrow-right-alt-icon"
          loading="lazy"
          alt=""
          src="../../../../../assets/images/icon/arrow-right-alt.svg"
        />
      </div>
    </div>
  </div>
</div>
