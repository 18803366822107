<div class="root">
  <div class="w-1280 py-0">
    <div class="row mt-5">
      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/roi.png" />
      </div>

      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Live ROI Metrics</div>
        <div class="streamline">
          Track financial impact with sophistication. Monitor CSR performance
          with detailed ROI metrics.
        </div>
        <div class="whatever_your fw-bolder">How It Works:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Input corporate spending and campaign details.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />View live ROI metrics dynamically updated in real-time.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Analyze financial efficiency, employee engagement, and community
            impact scores.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
