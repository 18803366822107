import { Component } from '@angular/core';

@Component({
  selector: 'app-chp5-employee-engagement',
  templateUrl: './chp5-employee-engagement.component.html',
  styleUrl: './chp5-employee-engagement.component.scss'
})
export class Chp5EmployeeEngagementComponent {

}
