<div *ngIf="topCampaignFiltering.length > 0 || fundRaiserDonorFiltering.length > 0 || donorFiltering.length > 0 " class="root">
  <h3 class="discover-shohay">Hall of Fame</h3>
  <div class="empowering-ngos-in">
    Celebrating Our Top Donors, Campaigns, and Fundraisers on shohay
  </div>
  <section class="navigation">
    <div class="action-buttons1">
      <h3 class="learn">Top Campaigns</h3>
      <div
        *ngFor="let featuredCampaigns of topCampaignFiltering; let i = index"
        class="loop_div_"
      >
        <div class="slide_div" (click)="navigateToCampaign(featuredCampaigns)">
          <div class="inner_half_slide">
            <div class="info_div cursor">
              <div
                class="left_side"
                [ngStyle]="{
                  'background-image':
                    'url(' + featuredCampaigns?.cover_img_vdo.url + ')'
                }"
              >
                <div class="yellow_title_div" *ngIf="featuredCampaigns.is_featured">
                  <div class="yellow_title">Featured</div>
                </div>
              </div>
              <div class="right_side">
                <div class="ngo_name">
                  {{ featuredCampaigns?.ngo_name }}
                </div>
                <div class="campaign_title">
                  {{ featuredCampaigns?.title }}
                </div>
                <div class="cont">
                  <dx-progress-bar
                    class="progress_bar"
                    [min]="0"
                    [max]="featuredCampaigns?.fundraising_target"
                    [value]="featuredCampaigns?.total_fund_raised || 2000"
                    [showStatus]="false"
                  ></dx-progress-bar>
                </div>
                <div class="campaign_target">
                  ৳{{ featuredCampaigns?.total_fund_raised | number }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="inner_half_slide">
              <div class="info_div cursor">
                <div
                  class="left_side"
                  [ngStyle]="{
                    'background-image':
                      'url(' + featuredCampaigns?.cover_img_vdo?.url + ')'
                  }"
                >
                  <div class="yellow_title_div">
                    <div class="yellow_title">Featured</div>
                  </div>
                </div>
                <div class="right_side">
                  <div class="ngo_name">
                    {{ featuredCampaigns?.ngo_name }}
                  </div>
                  <div class="campaign_title">
                    {{ featuredCampaigns?.title }}
                  </div>
                  <div>
                    <dx-progress-bar
                      class="progress_bar"
                      [min]="0"
                      [max]="featuredCampaigns?.fundraising_target"
                      [value]="featuredCampaigns?.total_fund_raised || 0"
                      [showStatus]="false"
                    ></dx-progress-bar>
                  </div>
                  <div class="campaign_target">
                    ৳{{ featuredCampaigns?.fundraising_target }}
                  </div>
                </div>
              </div>
            </div>
            <div class="inner_half_slide">
              <div class="info_div cursor">
                <div
                  class="left_side"
                  [ngStyle]="{
                    'background-image':
                      'url(' + featuredCampaigns?.cover_img_vdo?.url + ')'
                  }"
                >
                  <div class="yellow_title_div">
                    <div class="yellow_title">Featured</div>
                  </div>
                </div>
                <div class="right_side">
                  <div class="ngo_name">
                    {{ featuredCampaigns?.ngo_name }}
                  </div>
                  <div class="campaign_title">
                    {{ featuredCampaigns?.title }}
                  </div>
                  <div>
                    <dx-progress-bar
                      class="progress_bar"
                      [min]="0"
                      [max]="featuredCampaigns?.fundraising_target"
                      [value]="featuredCampaigns?.total_fund_raised || 0"
                      [showStatus]="false"
                    ></dx-progress-bar>
                  </div>
                  <div class="campaign_target">
                    ৳{{ featuredCampaigns?.fundraising_target }}
                  </div>
                </div>
              </div>
            </div> -->
        </div>
      </div>
      <div>
        <dx-button
          [disabled]="topCampaign.length <= 3"
          (click)="showPopup('campaign')"
          class="yellow-button-lg"
          >View All</dx-button
        >
      </div>
    </div>
    <div class="action-buttons1">
      <div class="top_part">
        <h3 class="learn">Top Fundraisers</h3>
        <div class="loop_div_container">
          <div
            class="loop_div"
            *ngFor="let list of fundRaiserDonorFiltering; let i = index"
          >
            <div class="fundraiser_card">
              <div class="left_side_property">
                <!-- <div class="trophy"> -->
                @if(i===0){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_0.svg'"
                  alt="trophy"
                />
                }@else if(i===1){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_1.svg'"
                  alt="trophy"
                />
                }@else if(i===2){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_2.svg'"
                  alt="trophy"
                />
                }@else {
                <div class="trophy_img_">{{ i + 1 }}</div>
                }

                <!-- </div> -->
                <!-- <div> -->
                <img
                  class="profile_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/profile_icon_gray.png'"
                  alt="profile_pic"
                />
                <!-- </div> -->
                <div class="name_amount">
                  <div class="name">{{ list.fundraiser_name }}</div>
                  <div class="amount">৳ {{ list.total_fund_raised }}</div>
                </div>
              </div>
              <div class="right_side_property">
                <img
                  class="heart"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/heart_hand.svg'"
                  alt="heart"
                />
                <div class="number">({{ list.total_team }})</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <dx-button
          [disabled]="fundRaiserDonor.length <= 5"
          class="yellow-button-lg"
          (click)="showPopup('fundraiser')"
          >View All</dx-button
        >
      </div>
    </div>
    <div class="action-buttons1">
      <div class="top_part">
        <h3 class="learn">Top Donors</h3>
        <div class="loop_div_container">
          <div
            class="loop_div"
            *ngFor="let list of donorFiltering; let i = index"
          >
            <div class="fundraiser_card">
              <div class="left_side_property">
                @if(i===0){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_0.svg'"
                  alt="trophy"
                />
                }@else if(i===1){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_1.svg'"
                  alt="trophy"
                />
                }@else if(i===2){
                <img
                  class="trophy_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/trophy_2.svg'"
                  alt="trophy"
                />
                }@else {
                <div class="trophy_img_">{{ i + 1 }}</div>
                }
                <img
                  class="profile_img"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/profile_icon_gray.png'"
                  alt="profile_pic"
                />
                <div class="name_amount">
                  <div class="name">{{ list?.name || 'not available' }}</div>
                  <div class="amount">৳ {{ (list?.total_amount | number) || 0 }}</div>
                </div>
              </div>
              <div class="right_side_property">
                <img
                  class="heart"
                  loading="lazy"
                  [src]="'../../../../../assets/images/icon/heart_hand.svg'"
                  alt="heart"
                />
                <div class="number">({{ (list?.total_donation | number) || 0 }})</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <dx-button
          [disabled]="donation.length <= 5"
          class="yellow-button-lg"
          (click)="showPopup('donor')"
          >View All</dx-button
        >
      </div>
    </div>
  </section>
</div>

<dx-popup
  class="popUp"
  [visible]="showDonationPopUp"
  [maxWidth]="721"
  [maxHeight]="802"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [showTitle]="true"
  [title]="popupTitle"
  [dragEnabled]="false"
  (onHidden)="closePopup()"
>
  @if(topFundraiserListPopUp){
  <div class="loop_div_container">
    <div class="loop_div" *ngFor="let list of fundRaiserDonor; let i = index">
      <div class="fundraiser_card">
        <div class="left_side_property">
          <!-- <div class="trophy"> -->
          @if(i===0){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_0.svg'"
            alt="trophy"
          />
          }@else if(i===1){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_1.svg'"
            alt="trophy"
          />
          }@else if(i===2){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_2.svg'"
            alt="trophy"
          />
          }@else {
          <div class="trophy_img_">{{ i + 1 }}</div>
          }

          <!-- </div> -->
          <!-- <div> -->
          <img
            class="profile_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/profile_icon_gray.png'"
            alt="profile_pic"
          />
          <!-- </div> -->
          <div class="name_amount">
            <div class="name">{{ list.fundraiser_name }}</div>
            <div class="amount">৳ {{ list.total_fund_raised }}</div>
          </div>
        </div>
        <div class="right_side_property">
          <img
            class="heart"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/heart_hand.svg'"
            alt="heart"
          />
          <div class="number">({{ list.total_team }})</div>
        </div>
      </div>
    </div>
  </div>
  }@else if (topDonationListPopUp) {

  <div class="loop_div_container">
    <div class="loop_div" *ngFor="let list of donation; let i = index">
      <div class="fundraiser_card">
        <div class="left_side_property">
          @if(i===0){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_0.svg'"
            alt="trophy"
          />
          }@else if(i===1){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_1.svg'"
            alt="trophy"
          />
          }@else if(i===2){
          <img
            class="trophy_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/trophy_2.svg'"
            alt="trophy"
          />
          }@else {
          <div class="trophy_img_">{{ i + 1 }}</div>
          }
          <img
            class="profile_img"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/profile_icon_gray.png'"
            alt="profile_pic"
          />
          <div class="name_amount">
            <div class="name">{{ list?.name || 'not available' }}</div>
            <div class="amount">৳ {{ (list?.total_amount | number) || 0 }}</div>
          </div>
        </div>
        <div class="right_side_property">
          <img
            class="heart"
            loading="lazy"
            [src]="'../../../../../assets/images/icon/heart_hand.svg'"
            alt="heart"
          />
          <div class="number">({{ (list?.total_donation | number) || 0 }})</div>
        </div>
      </div>
    </div>
  </div>

  }@else if(topCampaignPopUp){
  <div class="loop_div_container">
    <div
      *ngFor="let featuredCampaigns of topCampaign; let i = index"
      class="loop_div"
    >
      <div class="slide_div">
        <div class="inner_half_slide">
          <div
            class="info_div cursor"
            (click)="navigateToCampaign(featuredCampaigns)"
          >
            <div
              class="left_side"
              [ngStyle]="{
                'background-image':
                  'url(' + featuredCampaigns?.cover_img_vdo.url + ')'
              }"
            >
              <div class="yellow_title_div" *ngIf="featuredCampaigns.is_featured">
                <div class="yellow_title">Featured</div>
              </div>
            </div>
            <div class="right_side">
              <div class="ngo_name">
                {{ featuredCampaigns?.ngo_name }}
              </div>
              <div class="campaign_title">
                {{ featuredCampaigns?.title }}
              </div>
              <div class="cont">
                <dx-progress-bar
                  class="progress_bar"
                  [min]="0"
                  [max]="featuredCampaigns?.fundraising_target"
                  [value]="featuredCampaigns?.total_fund_raised || 2000"
                  [showStatus]="false"
                ></dx-progress-bar>
              </div>
              <div class="campaign_target">
                ৳{{ featuredCampaigns?.total_fund_raised | number }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="inner_half_slide">
              <div class="info_div cursor">
                <div
                  class="left_side"
                  [ngStyle]="{
                    'background-image':
                      'url(' + featuredCampaigns?.cover_img_vdo?.url + ')'
                  }"
                >
                  <div class="yellow_title_div">
                    <div class="yellow_title">Featured</div>
                  </div>
                </div>
                <div class="right_side">
                  <div class="ngo_name">
                    {{ featuredCampaigns?.ngo_name }}
                  </div>
                  <div class="campaign_title">
                    {{ featuredCampaigns?.title }}
                  </div>
                  <div>
                    <dx-progress-bar
                      class="progress_bar"
                      [min]="0"
                      [max]="featuredCampaigns?.fundraising_target"
                      [value]="featuredCampaigns?.total_fund_raised || 0"
                      [showStatus]="false"
                    ></dx-progress-bar>
                  </div>
                  <div class="campaign_target">
                    ৳{{ featuredCampaigns?.fundraising_target }}
                  </div>
                </div>
              </div>
            </div>
            <div class="inner_half_slide">
              <div class="info_div cursor">
                <div
                  class="left_side"
                  [ngStyle]="{
                    'background-image':
                      'url(' + featuredCampaigns?.cover_img_vdo?.url + ')'
                  }"
                >
                  <div class="yellow_title_div">
                    <div class="yellow_title">Featured</div>
                  </div>
                </div>
                <div class="right_side">
                  <div class="ngo_name">
                    {{ featuredCampaigns?.ngo_name }}
                  </div>
                  <div class="campaign_title">
                    {{ featuredCampaigns?.title }}
                  </div>
                  <div>
                    <dx-progress-bar
                      class="progress_bar"
                      [min]="0"
                      [max]="featuredCampaigns?.fundraising_target"
                      [value]="featuredCampaigns?.total_fund_raised || 0"
                      [showStatus]="false"
                    ></dx-progress-bar>
                  </div>
                  <div class="campaign_target">
                    ৳{{ featuredCampaigns?.fundraising_target }}
                  </div>
                </div>
              </div>
            </div> -->
      </div>
    </div>
  </div>
  }
</dx-popup>
