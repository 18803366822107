<div class="root">
  <div class="w-1280 py-0">
    <div class="row mt-5">
      <div class="col-md-12 col-lg-6">
        <img width="100%" src="/assets/images/Group_39579.jpg" />
      </div>

      <div
        class="d-flex gap-4 flex-column justify-content-around col-md-12 col-lg-6"
      >
        <div class="our_product">Visual Donation© Updates</div>
        <div class="streamline">
          Stay informed with real time photos and videos. Build trust through
          transparent and impactful updates.
        </div>
        <div class="whatever_your fw-bolder">How It Works:</div>
        <div class="features_div">
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />NGOs upload real-time photos and videos of campaign progress.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Corporate admins view updates in Shohay s dashboard.
          </span>
          <span>
            <img
              style="height: 24px; margin-right: 8px"
              src="/assets/images/icon/verified_icon_round.svg"
            />Employees stay engaged with live progress reports and visuals.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
