import { Component } from '@angular/core';

@Component({
  selector: 'app-chp1-hero',
  templateUrl: './chp1-hero.component.html',
  styleUrl: './chp1-hero.component.scss'
})
export class Chp1HeroComponent {

}
