import { Component } from '@angular/core';

@Component({
  selector: 'app-chp8-csr-campaigns',
  templateUrl: './chp8-csr-campaigns.component.html',
  styleUrl: './chp8-csr-campaigns.component.scss'
})
export class Chp8CsrCampaignsComponent {
  public campaignsList:any = [
    {
      cover_photo:'./assets/images/flood_2024_1.png',
      campaign_title:'Blanket Distribution in North Bengal',
      Collected_Amount: "BDT 1,65,620",
      Matched_Amount: "BDT 1,65,620",
      total_amount: "BDT 1,65,620",
    },
    {
      cover_photo:'./assets/images/flood_2024_2.png',
      campaign_title:'Blanket Distribution in North Bengal',
      Collected_Amount: "BDT 1,65,620",
      Matched_Amount: "BDT 1,65,620",
      total_amount: "BDT 1,65,620",
    },
  ];
}
